<template>
	<div>
		<div class="box" @click.self="shopShow = false" v-loading.fullscreen.lock="fullscreenLoading">
			<ExportExcel :json_data="json_data" :json_fields="json_fields" name="小红书.xls" />
			<div class="ct_box">
				<div class="tab_ul flex" style="justify-content: space-between">
					<UserMediaNav title="6" />
					<!-- <button class="null_but">清空筛选</button> -->
				</div>
				<div class="type2 flex">
					<p>行业类型：</p>
					<ul class="flex ul2">
						<li :class="industryType.title == '不限' ? 'active' : ''" @click="industryShow('不限', 0)">
							不限
						</li>
						<li :class="industryType.title == item ? 'active' : ''" v-for="(item, index) in industry_list"
							:key="index" @click="industryShow(item, index)">
							<!-- PortalType = item -->
							{{ item }}
						</li>
					</ul>
				</div>

				<div class="type2 flex">
					<p>受众区域：</p>
					<ul class="flex ul2">
						<li :class="TerritoryType.title == '不限' ? 'active' : ''" @click="TerritoryShow('不限', 0)">
							不限
						</li>
						<li :class="TerritoryType.title == item ? 'active' : ''" v-for="(item, index) in TerritoryList"
							:key="index" @click="TerritoryShow(item, index)">
							{{ item }}
						</li>
					</ul>
				</div>
				<div class="type2 flex">
					<p>图文价格：</p>
					<ul class="flex ul2">
						<li :class="RecordingType.title == '不限' ? 'active' : ''" @click="RecordingShow('不限', 0)">
							不限
						</li>
						<li :class="RecordingType.title == item ? 'active' : ''" v-for="(item, index) in RecordingList"
							:key="index" @click="RecordingShow(item, index)">
							{{ item }}
						</li>
					</ul>
				</div>
				<div class="type2 flex">
					<p>视频价格：</p>
					<ul class="flex ul2">
						<li :class="video_price_rangeType.title == '不限' ? 'active' : ''"
							@click="video_price_rangeShow('不限', 0)">
							不限
						</li>
						<li :class="video_price_rangeType.title == item ? 'active' : ''"
							v-for="(item, index) in video_price_rangeList" :key="index"
							@click="video_price_rangeShow(item, index)">
							{{ item }}
						</li>
					</ul>
				</div>
				<div class="type2 flex">
					<p>受众性别：</p>
					<ul class="flex ul2">
						<li :class="audience_sexType.title == '不限' ? 'active' : ''" @click="audience_sexShow('不限', 0)">
							不限
						</li>
						<li :class="audience_sexType.title == item ? 'active' : ''"
							v-for="(item, index) in audience_sex_list" :key="index"
							@click="audience_sexShow(item, index)">
							{{ item }}
						</li>
					</ul>
				</div>
				<div class="type2 flex">
					<p>受众年龄：</p>
					<ul class="flex ul2">
						<li :class="AgeType.title == '不限' ? 'active' : ''" @click="AgeShow('不限', 0)">
							不限
						</li>
						<li :class="AgeType.title == item ? 'active' : ''" v-for="(item, index) in AgeList" :key="index"
							@click="AgeShow(item, index)">
							{{ item }}
						</li>
					</ul>
				</div>
				<div class="type2 flex">
					<p>特殊类目：</p>
					<ul class="flex ul2">
						<li :class="SpecialType.title == '不限' ? 'active' : ''" @click="SpecialShow({
			id: 0,
			title: '不限'
		})">不限</li>
						<li v-show="SpecialList.is_pub_weekend" :class="SpecialType.title == '周末可发' ? 'active' : ''"
							@click="SpecialShow({
			id: 1,
			title: '周末可发'
		})">
							{{ SpecialList.is_pub_weekend }}
						</li>
						<!-- 新加特殊类目项:是否可加配图 是否可加视频 -->
						<li v-show="SpecialList.is_pub_video" :class="SpecialType.title == '可加视频' ? 'active' : ''"
							@click="SpecialShow({
			id: 2,
			title: '可加视频'
		})">
							{{ SpecialList.is_pub_video }}
						</li>
					</ul>
				</div>
				<div class="type2 flex">
					<p>排序：</p>
					<ul class="flex ul2">
						<li @click="StateShow('默认')" :class="SortType.title == '默认' ? 'active' : ''">
							默认
						</li>
						<li v-if="SortList.member_image_text_price"
							@click="StateShow('图文价格', 'member_image_text_price')" :id="SortType.title == '图文价格' && MoneyState == 1 ? 'activeTrue'
			: SortType.title == '图文价格' && MoneyState == 2 ? 'activeFalse' : ''"
							:class="SortType.title == '图文价格' ? 'active' : ''">
							图文价格
						</li>
						<li v-if="SortList.member_video_price" @click="StateShow('视频价格', 'member_video_price')" :id="SortType.title == '视频价格' && VideoMoneyState == 1 ? 'activeTrue'
			: SortType.title == '视频价格' && VideoMoneyState == 2 ? 'activeFalse' : ''"
							:class="SortType.title == '视频价格' ? 'active' : ''">
							视频价格
						</li>
					</ul>
				</div>
				<div class="type4 flex">
					<p class="type_title_n"> 媒介名称：</p>
					<el-input @keyup.enter.native="page = 1; ListFn()" v-model="mediaName"
						placeholder="请输入编号或名称"></el-input>
					<div class="flex link_box">
						<p>可发链接：</p>
						<el-select v-model="link" placeholder="请选择">
							<el-option v-for="(item, index) in linkList" :key="index" :label="item" :value="item">
							</el-option>
						</el-select>
					</div>
					<div class="flex link_box">
						<p>可发联系方式：</p>
						<el-select v-model="relation" placeholder="请选择">
							<el-option v-for="(item, index) in linkList" :key="index" :label="item" :value="item">
							</el-option>
						</el-select>
					</div>
					<button class="sf_but" type="primary" @click="page = 1; ListFn()">筛选</button>
					<button class="sf_but2" type="primary" @click="page = 1; AllMediaTab()">
						重置筛选
					</button>
					<mediaWhiteList />
				</div>
			</div>
			<div class="table_box">
				<Title title="资源列表" />
				<div class="table_bg_box">
					<div class="flex" style="justify-content: space-between;padding: 0 2rem;box-sizing: border-box;">
						<div></div>
						<ul class="SetMenu_tab flex">
							<li @click="SetMenuTab(0)" :class="collect == 0 ? 'SetMenu_Show' : ''">
								全部媒介
							</li>
							<li @click="SetMenuTab(1)" :class="collect == 1 ? 'SetMenu_Show' : ''">
								我的收藏
							</li>
						</ul>
					</div>
					<table class="table_2">
						<thead>
							<td>媒介名称</td>
							<td>小红书号</td>
							<td>图文价格</td>
							<td>视频价格</td>
							<td>行业类型</td>
							<td>所属区域</td>
							<td>图文阅读量</td>
							<td>视频播放量</td>
							<td>参考粉丝数</td>
							<td>受众性别</td>
							<td>受众年龄</td>

							<td>备注</td>
							<td>操作</td>
						</thead>
						<tbody>
							<tr v-for="(item, index) in tableData" :key="index">
								<td>
									<td_remark :text="item.title" />
								</td>
								<td>
									<td_remark :text="item.account" />

								</td>
								<td>
									<button :class="item.check == 'member_image_text_price' ? 'priceActive' : 'price'"
										@click.stop="onPitch(index, 'member_image_text_price')">￥{{
			item.member_image_text_price.toFixed(2)
		}}
									</button>
								</td>
								<td>
									<button :class="item.check == 'member_video_price' ? 'priceActive' : 'price'"
										@click.stop="onPitch(index, 'member_video_price')">￥{{
			item.member_video_price.toFixed(2)
		}}</button>
								</td>
								<td>{{ item.industry_classify_title }}</td>
								<td>{{ item.area_title }}</td>
								<td>{{ item.image_text_views }}</td>
								<td>{{ item.video_views }}</td>
								<td>{{ item.fans_num }}</td>
								<td>{{ item.audience_sex == 0 ? '不限' : item.audience_sex == 1 ? '女性粉丝偏多' :
			item.audience_sex == 2 ? '男性粉丝偏多' : '男女粉丝均衡' }}</td>
								<td>{{ item.audience_age == 0 ? '不限' : item.audience_age == 1 ? '0-17岁偏多' :
			item.audience_age == 2 ? '18-24岁偏多' : item.audience_age == 0 ? '25-34岁偏多' :
				item.audience_age == 0 ? '35-44岁偏多' : '44岁以上偏多' }}</td>
								<td>
									<td_remark :text="item.remark" />

								</td>
								<td>
									<div class="collect_box flex">
										<p @click.stop="jumpLink(item)">查看案例</p>
										<!-- v-if="item.collect == 0"  -->
										<p v-if="item.is_collect == 0" class="collect" @click.stop="collectGet(item)">收藏
										</p>
										<!-- v-if="item.collect == 1" -->
										<p v-if="item.is_collect == 1" class="collect2"
											@click.stop="collectClose(item)">已收藏
										</p>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
					<!-- 分页器 -->
					<pagination :page="page" @pageChange="pageChange" :count="count" :total_page="total_page" />
				</div>
			</div>
		</div>
		<!-- 购物车 -->
		<div v-show="shopShow == false" class="shopping_car">
			<div class="car" @click.stop="shopShow = true">
				<p>{{ CarList.length }}</p>
			</div>
		</div>
		<div :class="shopShow ? 'shop_box' : 'shop_none_box'">
			<div class="title flex" style="justify-content: space-between; align-items: center">
				<h3 class="carTitle">已选媒体: {{ CarList.length }}</h3>
				<button class="empty_but" v-show="CarList.length != 0" @click="empty()">
					清空已选媒体
				</button>
				<span class="close" @click="shopShow = false"></span>
			</div>
			<ul class="listbox" v-show="CarList.length != 0">
				<li v-for="(item, index) in CarList" :key="index">
					<!-- <img :src="item.logo" alt="" /> -->
					<div>
						<td_remark :text="item.title" />
					</div>
					<p class="price">{{ item.check == 'member_image_text_price' ?
			`图文价格:￥${item.member_image_text_price.toFixed(2)}` :
			`视频价格:￥${item.member_video_price.toFixed(2)}` }}</p>
					<span class="deleteIcon" @click.stop="detale(item)"></span>
				</li>
			</ul>
			<el-empty v-show="CarList.length == 0" description="您还没有选择资源赶紧去看看心仪的资源吧"></el-empty>
			<div class="bottom_box">
				<div class="flex" style="justify-content: space-between; margin-bottom: 1rem">
					<p style="font-size: 1.4rem; color: #999">合计：</p>
					<p class="totalMoney">
						￥<span>{{ totalMoney }}</span>
					</p>
				</div>
				<button class="put_but" @click="putFn(item)">立即投放</button>
			</div>
		</div>
	</div>
</template>

<script>
import UserMediaNav from '@/components/UserMediaNav.vue'
import { mapState } from 'vuex'
import ExportExcel from '@/components/exportExcel.vue';
export default {
	name: '',
	created() { },
	mounted() {
		this.fullscreenLoading = true

		setTimeout(() => {
			for (const i in this.$store.state.UserMedia) {
				if (this.$store.state.UserMedia[i].id == 6) {
					console.log(this.$store.state.UserMedia)
					this.industry_list = this.$store.state.UserMedia[i].industry_list
					this.TerritoryList = this.$store.state.UserMedia[i].area_list
					this.ChannelList = this.$store.state.UserMedia[i].fans_list
					this.RecordingList = this.$store.state.UserMedia[i].image_text_price_range
					this.video_price_rangeList = this.$store.state.UserMedia[i].video_price_range
					this.views_list = this.$store.state.UserMedia[i].views_list
					this.audience_sex_list = this.$store.state.UserMedia[i].audience_sex_list
					this.SpecialList = this.$store.state.UserMedia[i].special_list
					this.AgeList = this.$store.state.UserMedia[i].audience_age_list
					this.SortList = this.$store.state.UserMedia[i].order_list
					this.special_list = this.$store.state.UserMedia[i].special_list
				}
			}

			this.ListFn()
			this.fullscreenLoading = false
		}, 300)
	},
	computed: {
		...mapState(['UserMedia', 'MediaNav', 'UserTypeItem', 'fillActive']),
		totalMoney: function () {
			var n = 0;
			for (var i = 0; i < this.CarList.length; i++) {
				if (this.CarList[i].check == 'member_image_text_price') {
					// this.CarList.push(this.tableData[i])
					n += this.CarList[i].member_image_text_price;
				} else if (this.CarList[i].check == 'member_video_price') {
					// this.CarList.push(this.tableData[i])
					n += this.CarList[i].member_video_price;
				}
			}
			return n.toFixed(2);
		}
	},
	watch: {
		CarList: {
			handler() {
				this.excelPush()
			},
			deep: true,
		}
	},
	components: {
		UserMediaNav,
		ExportExcel
	},
	data() {
		return {
			industryType: {
				id: 0,
				title: '不限',
			},
			industry_list: [],
			TerritoryType: {
				id: 0,
				title: '不限',
			},
			TerritoryList: [],
			RecordingType: {
				id: 0,
				title: '不限',
			},
			video_price_rangeType: {
				id: 0,
				title: '不限'
			},
			// 视频价格
			video_price_rangeList: [],
			// 性别
			audience_sexType: {
				id: 0,
				title: '不限'
			},
			audience_sex_list: [],
			RecordingList: [],
			// 价格区间
			MoneyType: {
				id: 0,
				title: '不限',
			},
			MoneyList: [],
			// 特殊类目
			SpecialType: {
				id:0,
				title:'不限'
			},
			SpecialList: [],
			// 排序
			SortType: {
				id: 0,
				title: '默认',
			},
			SortList: [],
			special_list: [],
			audience_sex_Type: {
				id: 0,
				title: '不限'
			},
			MoneyState: 0, //价钱升序降序控制器
			CopmutedState: 0, //电脑权重升序降序控制器
			MoveState: 0, //移动权重升序降序控制器
			/*-----------------媒体筛选------------------------*/
			mediaName: '', // 媒介名称,
			linkList: ['是', '否'], //可发链接列表
			link: '', //可发链接
			relation: '', //可发联系方式
			// 受众年龄
			AgeList: [],
			AgeType: {
				id: 0,
				title: '不限'
			},
			/*数据表格*/
			tableData: [],
			collect: 0,
			CarList: [],
			// 购物车任务栏切换
			shopShow: false,
			Allcheck: false,
			AllcheckNum: 0, //选中总数
			fullscreenLoading: false,
			total_page: 0, //分页总页数
			page: 1,
			count: 1,
			json_fields: {
				"小红书号": 'title',
				'账户': 'account',
				"图文价格": 'member_image_text_price',
				"视频价格": 'member_video_price',
				"图文阅读量": 'image_text_views',
				"案例链接": 'case_url',
				"所属区域": 'area_title',
				"可发内链": 'is_pub_url',
				"可发联系方式": 'is_pub_contact',
				"价格": 'price',
				"粉丝数": 'fans_num',
				"受众性别": 'audience_sex',
				"受众年龄": 'audience_age',
				"备注": 'remark',
			},
			json_data: []
		}
	},
	methods: {
		pageChange(pageVal) {
			this.page = pageVal
			this.ListFn()
		},
		// 切换收藏列表
		SetMenuTab(num) {
			this.collect = num
			this.page = this.pageChangeOne()
			this.ListFn()
		},
		StateShow(item, index) {
			/*
			每次点击时需要重置价格-电脑权重-移动权重的状态,在每个判断条件中需做重置
			*/
			this.SortType = {
				id: index,
				title: item,
			}
			switch (item) {
				case '默认':
					this.MoneyState = 0
					this.VideoMoneyState = 0
					this.SortType.id = 0
					break

				case '图文价格':
					this.VideoMoneyState = 0
					if (this.MoneyState == 0) {
						this.MoneyState = 1
					} else if (this.MoneyState == 1) {
						this.MoneyState = 2
					} else {
						this.MoneyState = 1
					}
					break

				case '视频价格':
					this.MoneyState = 0
					if (this.VideoMoneyState == 0) {
						this.VideoMoneyState = 1
					} else if (this.VideoMoneyState == 1) {
						this.VideoMoneyState = 2
					} else {
						this.VideoMoneyState = 1
					}
					break

				default:
					break
			}
			this.page = this.pageChangeOne()
			this.ListFn()
		},
		onPitch(index, type) {
			this.shopShow = true
			console.log('tableDate:', this.tableData[index].check, type);
			// this.tableData[index].check = type
			const result = this.CarList.every(item => item.id !== this.tableData[index].id);
			if (result == true) {
				this.tableData[index].check = type
				this.CarList.push(this.tableData[index])
			} else {
				this.CarList.forEach((item, i) => {
					if (this.CarList[i].id == this.tableData[index].id && this.CarList[i].check != type) {
						console.log(this.CarList[i].check);
						this.tableData[index].check = type
						this.CarList[i].check = type
					} else if (this.CarList[i].id == this.tableData[index].id && this.tableData[index].check == type) {
						console.log(2);
						this.CarList.splice(i, 1)
						this.tableData[index].check = ''
					}
				})
			}
		},
		// 清空购物车
		empty() {
			this.$confirm('是否清空已选媒体?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					this.fullscreenLoading = true
					// setTimeout(() => {
					this.CarList = []
					this.shopShow = false
					// this.toggleSelection()
					this.tableData.forEach((item, index) => {
						if (item.check) {
							console.log(item)
							return (item.check = false)
						}
					})
					this.fullscreenLoading = false
					// }, 500)
					this.$message({
						type: 'success',
						message: '已清空已选媒体',
					})
				})
				.catch(() => {
					this.$message({
						type: 'warning',
						message: '已删除',
					})
				})
		},
		createBall(left, top) {
			const bar = document.createElement('ball')
			bar.style.position = 'fixed'
			bar.style.left = left + 'px'
			bar.style.top = top + 'px'
			bar.style.width = '20px'
			bar.style.height = '20px'
			bar.style.borderRadius = '50%'
			bar.style.backgroundColor = 'red'
			bar.style.transition =
				'left 1s linear, top 1s cubic-bezier(0.5, -0.5, 1, 1)'
			document.body.appendChild(bar)
			setTimeout(() => {
				const x = document.body.clientWidth * 1.5
				const y = document.body.clientHeight * 1 - 500
				bar.style.top = y + 'px'
				console.log(y)
				bar.style.left = x + 'px'
			}, 0)
			bar.ontransitionend = function () {
				this.remove()
			}
		},

		// 门户类型切换
		industryShow(item, index) {
			console.log(index)
			this.industryType = {
				title: item,
				id: index ? parseInt(index) : 0,
			}
			this.page = this.pageChangeOne()
			this.ListFn()
		},
		// 所属区域类型切换
		TerritoryShow(item, index) {
			this.TerritoryType = {
				title: item,
				id: index ? parseInt(index) : 0,
			}
			this.page = this.pageChangeOne()
			this.ListFn()
		},
		audience_sexShow(item, index) {
			this.audience_sexType = {
				title: item,
				id: index ? parseInt(index) : 0,
			}
			this.page = this.pageChangeOne()
			this.ListFn()

		},
		RecordingShow(item, index) {
			this.RecordingType = {
				title: item,
				id: index ? parseInt(index) : 0,
			}
			this.page = this.pageChangeOne()
			this.ListFn()
		},
		// 视频价格
		video_price_rangeShow(item, index) {
			this.video_price_rangeType = {
				title: item,
				id: index ? parseInt(index) : 0,
			}
			console.log(this.Recording, '收录效果')
			this.page = this.pageChangeOne()
			this.ListFn()
		},
		// 价格区间切换
		MoneyShow(item, index) {
			this.MoneyType = {
				title: item,
				id: index ? parseInt(index) : 0,
			}
			console.log(this.MoneyType, '价格区间')
			this.page = this.pageChangeOne()
			this.ListFn()
		},
		// 受众年龄
		AgeShow(item, index) {
			this.AgeType = {
				title: item,
				id: index ? parseInt(index) : 0,
			}
			this.page = this.pageChangeOne()
			this.ListFn()
		},
		// 特殊类目切换
		SpecialShow(item) {
			this.SpecialType = item
			this.page = this.pageChangeOne()
			this.ListFn()
		},
		// 获取列表数据
		ListFn() {
			let data = {}
			data.page = this.page
			data.category_id = 6
			data.limit = 20
			if (this.mediaName != '') {
				data.title = this.mediaName
			}
			if (this.industryType.id != 0) {
				data.industry_id = this.industryType.id
			}
			if (this.TerritoryType.id != 0) {
				data.area_id = this.TerritoryType.id
			}
			if (this.AgeType.title != '不限') {
				data.audience_age = this.AgeType.id
			}
			if (this.MoneyType.id != 0) {
				data.price_id = this.MoneyType.id
			}
			if (this.RecordingType.id != 0) {
				data.image_text_price_range = this.RecordingType.id
				// data.is_v_auth = this.RecordingType.id
			}
			if (this.audience_sexType.id != 0) {
				data.audience_sex = this.audience_sexType.id
			}
			if (this.video_price_rangeType.id != 0) {
				data.video_price_range = this.video_price_rangeType.id
			}
			if (this.link != '' && this.link == '是') {
				data.is_pub_url = 1
			} else if (this.link !== '' && this.link == '否') {
				data.is_pub_url = 0
			}
			if (this.SpecialType.id == 1) {
				data.is_pub_weekend = 1
			} else if (this.SpecialType.id == 2) {
				data.is_pub_video = 1
			}
			if (this.SortType.title == '图文价格') {
				data.sort = 'member_image_text_price'
				if (this.MoneyState == 1) {
					data.order = 'asc'
				} else {
					data.order = 'desc'
				}
			} else if (this.SortType.title == '视频价格') {
				data.sort = 'member_video_price'
				if (this.VideoMoneyState == 1) {
					data.order = 'asc'
				} else {
					data.order = 'desc'
				}
			}
			data.collect = this.collect
			this.fullscreenLoading = true
			this.curlGet('/api/medium/list', data).then((res) => {
				if (res.data.code) {
					this.tableData = res.data.data.list
					this.total_page = res.data.data.total_page
					this.count = res.data.data.count
					this.tableData.forEach((item, index) => {
						this.CarList.forEach((el, i) => {
							if (el.check && item.id == el.id) {
								this.$set(this.tableData[index], 'check', el.check)
							} else {
								this.$set(this.tableData[index], 'check', '')

							}
						})
					})
					this.shopDate(this.tableData, this.$store.state.shopObj, this.CarList)
				}
				this.fullscreenLoading = false
			})
		},
		excelPush() {
			this.json_data = []
			this.CarList.forEach((item, index) => {
				this.json_data.push({
					title: item.title,
					account: item.account,
					member_image_text_price: item.member_image_text_price,
					member_videoe_price: item.member_video_price,
					image_text_views: item.image_text_views,
					case_url: item.case_url,
					area_title: item.area_title,
					is_pub_url: item.is_pub_url ? '是' : '否',
					is_pub_contact: item.is_pub_contact ? '是' : '否',
					fans_num: item.fans_num,
					audience_sex: item.audience_sex ? '男' : '女',
					audience_age: item.audience_age == 0 ? '不限' : item.audience_age == 1 ? '0-17岁偏多' : item.audience_age == 2 ? '18-24岁偏多' : item.audience_age == 0 ? '25-34岁偏多' : item.audience_age == 0 ? '35-44岁偏多' : '44岁以上偏多',
					remark: item.remark
				})
			})
		},
		// 重置选择
		AllMediaTab() {
			this.industryType = {
				id: 0,
				title: '不限',
			}
			this.ChannelType = {
				id: 0,
				title: '不限',
			}
			this.TerritoryType = {
				id: 0,
				title: '不限',
			}
			this.PriceType = {
				id: 0,
				title: '不限',
			}
			this.MoneyType = {
				id: 0,
				title: '不限',
			}
			this.VideoPriceType = {
				id: 0,
				title: '不限',
			}
			this.RecordingType = {
				id: 0,
				title: '不限',
			}
			this.FansNumType = {
				id: 0,
				title: '不限',
			}
			this.SortType = {
				id: 0,
				title: '默认',
			}
			this.SexType = {
				id: 0,
				title: '不限'
			}
			this.AgeType = {
				id: 0,
				title: '不限'
			}
			this.AgeType = {
				id: 0,
				title: '不限'
			}
			this.video_price_rangeType = {
				id: 0,
				title: '不限'
			}
			this.audience_sexType = {
				id: 0,
				title: '不限'
			}
			this.SpecialType = '不限'
			this.mediaName = ''
			this.link = ''
			this.relation = ''
			this.page = this.pageChangeOne()
			this.ListFn()
		},
		// 筛选
		// 判断切换数据时是否选中该条数据
		TabCheck() {
			this.AllcheckNum = 0
			this.fullscreenLoading = true
			setTimeout(() => {
				this.tableData.forEach((item, index) => {
					console.log(this.ids);
					console.log(item.id, 'id');
					if (this.ids.indexOf(item.id) >= 0) {
						this.AllcheckNum += 1
						// if(this.AllcheckNum == this.limit){
						//     this.Allcheck = true
						// }
						return this.tableData[index].check = true
					}
				})
				this.fullscreenLoading = false

			}, 1000)
		},
		// 删除
		detale(element) {
			this.CarList.forEach((el, i) => {
				if (el.id == element.id) {
					this.CarList.splice(i, 1)
				}
			})
			// this.CarList.splice(index,1)
			this.tableData.forEach((item, index) => {
				if (item.id == element.id) {
					this.tableData[index].check = ''
				}
			})
		},
		// 立即投放
		putFn() {
			if (this.CarList.length == 0) {
				return this.$message({
					type: 'warning',
					message: '请选择媒介',
				})
			} else if (!this.$user_info) {
				this.$message({
					type: 'warning',
					message: '请登录',
				})
			} else {
				this.fullscreenLoading = true
				this.CarList.forEach((item, index) => {
					this.CarList[index].price = this.CarList[index][this.CarList[index].check]
					console.log(this.CarList[index]);
				})
				setTimeout(() => {
					this.fullscreenLoading = false
					this.$store.commit('shopPush', this.CarList)
					if (this.$store.state.fillActive == false) {

						this.$router.push('/user/SetMenu/ManuscriptPreview')
					} else {
						this.$store.commit('childTab', '小红书发布')
						this.$router.push({
							path: '/user/SetMenu/fill?id=6',
						})
					}
				}, 500)
			}
		},
		// 收藏
		collectGet(item) {
			console.log(item);
			this.curlGet('/api/medium/collect', {
				mid: item.id,
				style: 1
			}).then(res => {
				console.log(res)
				if (res.data.code) {
					this.$message({
						message: '收藏成功',
						type: 'success'
					})
					this.ListFn()
				}
			})
		},
		// 取消收藏
		collectClose(item) {
			this.$confirm('是否取消收藏?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.curlGet('/api/medium/collect', {
					mid: item.id,
					style: 1
				}).then(res => {
					console.log(res)
					if (res.data.code) {
						this.$message({
							type: 'success',
							message: '取消收藏成功!'
						});
						this.ListFn()
					}
				})

			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消'
				});
			});

		},
	},
}
</script>

<style lang=scss scoped>
@import '../../scss/shopCar';

.box {
	position: relative;
	margin-bottom: 7.33rem;
	z-index: 999;

	.derive_but {
		width: 6.67rem;
		height: 2.5rem;
		border: 0;
		cursor: pointer;
		background: #108CDD;
		color: #fff;
		border-radius: 0.42rem;
		position: absolute;
		top: -45px;
		right: 0;
		z-index: 999;
	}

	// 数据
	.ct_box {
		width: 100%;
		background: #fff;
		margin: 0 auto;
		padding: 0 0;
		position: relative;
		border-radius: 0.88rem;
		box-shadow: 0.17rem 0.17rem 0.33rem #7a7a7a;

		.tab_ul {
			border-bottom: 0.1rem solid #cec8c8;
			padding: 0 1rem;
			box-sizing: border-box;
			align-items: center;

			.ul1 {
				padding: 0.5rem 0;
				align-items: center;

				li {
					padding: 1rem 1rem;
					font-size: 1.3rem;
					transition: 0.2s all;
					border-radius: 0.5rem;
					cursor: pointer;
					transition: 0.3s;

					&:hover {
						color: #168ad9;
						font-weight: 700;
					}
				}
			}

			.null_but {
				width: 6.67rem;
				height: 2.5rem;
				border-radius: 0.42rem;
				background: #108cdd;
				color: #fff;
				border: 0;
				box-shadow: 0.17rem 0.17rem 0.33rem #7a7a7a;
			}
		}

		.type2 {
			// padding: 0rem 1rem;
			box-sizing: border-box;
			border-bottom: 0.1rem solid #cec8c8;

			p {
				width: 95px;
				color: #000;
				text-align: right;
				font-size: 1.2rem;
				padding-top: 1.7rem;
			}

			.ul2 {
				width: 90%;
				flex-wrap: wrap;
				// border-bottom: 0.1rem solid #CEC8C8;
				align-items: center;
				padding: 1rem 0;

				li {
					padding: 0.7rem 1rem;
					font-size: 1.2rem;
					transition: 0.2s all;
					border-radius: 0.5rem;
					// margin-right: 1.5rem;
					cursor: pointer;

					&:hover {
						color: #168ad9;
					}
				}
			}
		}

		// 排序
		.type3 {
			// padding: 0rem 1rem;
			// box-sizing: border-box;
			border-bottom: 0.1rem solid #cec8c8;

			p {
				width: 95px;
				color: #000;
				text-align: right;
				font-size: 1.2rem;
				padding-top: 1.7rem;
			}

			.ul2 {
				width: 70%;
				flex-wrap: wrap;
				// border-bottom: 0.1rem solid #CEC8C8;
				align-items: center;
				padding: 1rem 0;

				li {
					padding: 0.7rem 2rem 0.7rem 0.7rem;
					font-size: 1.2rem;
					transition: 0.2s all;
					border-radius: 0.5rem;
					margin-right: 0.8rem;
					position: relative;
					cursor: pointer;

					&:nth-child(1) {
						margin-right: 0;
						padding: 0.7rem;
					}

					&:nth-child(2) {
						&::before {
							content: '';
							width: 1rem;
							height: 1rem;
							background: url('~@/assets/media/up.png') no-repeat;
							background-size: 100%;
							position: absolute;
							right: 5px;
							top: 10px;
						}

						&::after {
							content: '';
							width: 1rem;
							height: 1rem;
							background: url('~@/assets/media/down.png') no-repeat;
							background-size: 100%;
							position: absolute;
							right: 5px;
							top: 22px;
						}
					}

					&:nth-child(3) {
						&::before {
							content: '';
							width: 1rem;
							height: 1rem;
							background: url('~@/assets/media/up.png') no-repeat;
							background-size: 100%;
							position: absolute;
							right: 5px;
							top: 10px;
						}

						&::after {
							content: '';
							width: 1rem;
							height: 1rem;
							background: url('~@/assets/media/down.png') no-repeat;
							background-size: 100%;
							position: absolute;
							right: 5px;
							top: 22px;
						}
					}

					&:nth-child(4) {
						&::before {
							content: '';
							width: 1rem;
							height: 1rem;
							background: url('~@/assets/media/up.png') no-repeat;
							background-size: 100%;
							position: absolute;
							right: 5px;
							top: 10px;
						}

						&::after {
							content: '';
							width: 1rem;
							height: 1rem;
							background: url('~@/assets/media/down.png') no-repeat;
							background-size: 100%;
							position: absolute;
							right: 5px;
							top: 22px;
						}
					}
				}

				#activeTrue {
					padding: 0.7rem 2rem 0.7rem 0.7rem;
					font-size: 1.3rem;
					transition: 0.2s all;
					border-radius: 0.5rem;
					margin-right: 0.8rem;
					position: relative;
					cursor: pointer;

					&::before {
						content: '';
						width: 1rem;
						height: 1rem;
						background: url('~@/assets/media/up1.png') no-repeat;
						background-size: 100%;
						position: absolute;
						right: 5px;
						top: 10px;
					}
				}

				#activeFalse {
					padding: 0.7rem 2rem 0.7rem 0.7rem;
					font-size: 1.3rem;
					transition: 0.2s all;
					border-radius: 0.5rem;
					margin-right: 0.8rem;
					position: relative;
					cursor: pointer;

					&::after {
						content: '';
						width: 1rem;
						height: 1rem;
						background: url('~@/assets/media/down1.png') no-repeat;
						background-size: 100%;
						position: absolute;
						right: 5px;
						top: 22px;
					}
				}
			}
		}

		// 筛选
		.type4 {
			padding: 1rem 0rem;
			border-bottom: 0.1rem solid #cec8c8;
			align-items: center;

			.type_title_n {
				width: 90px;
				color: #000;
				text-align: right;
				font-size: 1.2rem;
				// padding-top: 1.7rem;
			}

			::v-deep .el-input {
				width: 15rem;
			}

			.link_box {
				align-items: center;
				padding: 0 1.5rem;

				p {
					color: #000;
					font-size: 1.2rem;
				}

				::v-deep .el-input--suffix {
					width: 10rem;
				}
			}

			.sf_but {
				cursor: pointer;
				border-radius: 0.2rem;
				padding: 1rem 2rem;
				border: 0;
				color: #fff;
				background: #108CDD;
				margin-left: 1rem;
			}

			.sf_but2 {
				cursor: pointer;
				border-radius: 0.2rem;
				padding: 1rem 2rem;
				border: 0;
				color: #fff;
				background: #108CDD;
				margin: 0 1rem;

			}
		}
	}

	.table_box {
		margin-top: 3rem;
		padding: 1rem 0;
		box-sizing: border-box;

		.table_bg_box {
			padding: 1.5rem 0 3rem 0;
			box-sizing: border-box;
			background: #fff;
			border-radius: 0.88rem;

			.title_box {
				padding: 0 2rem;
				box-sizing: border-box;
				justify-content: space-between;
				align-items: center;

				.search_box {
					width: 23.33rem;
					height: 3.33rem;
					border: 0.08rem solid #c5e6fc;
					border-radius: 0.42rem;
					box-shadow: 0.17rem 0.17rem 0.33rem#7A7A7A;
					align-items: center;
					padding-left: 1.2rem;
					box-sizing: border-box;
					justify-content: space-between;

					.search {
						display: block;
						width: 1.42rem;
						height: 1.42rem;

						background: url('~@/assets/user/setMenu/search.png') no-repeat;
						background-size: 100%;
					}

					.search_text {
						outline: none;
						border: 0;
						color: #6f90a6;
						font-size: 1.2rem;
					}

					.search_but {
						border: 0;
						color: #fff;
						font-size: 1.17rem;
						border-radius: 0 0.42rem 0.42rem 0;
						background: #b5e1ff;
						width: 5.83rem;
						height: 3.33rem;
						cursor: pointer;
					}
				}


			}

			.table_2 {
				width: 100%;
				margin: 20px auto;
				// border-spacing: 0;
				border-collapse: collapse;

				// 标题
				thead {
					border-bottom: 1px solid #e6e9f4;
					margin-bottom: 5rem;

					td {
						color: #0d8adc;
						font-weight: bold;
						font-size: 1.3rem;
						text-align: center;
						padding-bottom: 1.5rem;
						box-sizing: border-box;

					}
				}

				tbody {
					tr {
						border-bottom: 1px solid #e6e9f4;
						cursor: pointer;
						transition: 0.3s;

						&:last-child {
							border: 0
						}

						&:hover {
							background: #e0f3ff;
						}
					}

					td {
						color: #000;
						font-size: 1.22rem;
						text-align: center;
						padding: 1.3rem;
						box-sizing: border-box;
						position: relative;

						.price {
							background: url('~@/assets/media/none.png')no-repeat;
							border: 0;
							width: 8rem;
							height: 3rem;
							border-radius: 0.3rem;
							color: #000;
							background-size: cover;
							cursor: pointer;
						}

						.priceActive {
							background: url('~@/assets/media/active.png')no-repeat;
							border: 0;
							width: 8rem;
							height: 3rem;
							color: #000;
							border-radius: 0.3rem;
							background-size: cover;
							cursor: pointer;
						}

						.place_but {
							padding: 0.8rem 1rem 0.8rem 3.5rem;
							color: #fff;
							font-size: 1.22rem;
							background: #108cdd;
							border: 0;
							border-radius: 5px;
							position: relative;
							cursor: pointer;

							&::before {
								content: '';
								width: 1.8rem;
								height: 1.8rem;
								background: url('~@/assets/media/shopCar2.png') no-repeat;
								background-size: 100%;
								position: absolute;
								left: 1rem;
								top: 0.8rem;
							}
						}

						.collect_box {
							justify-content: space-between;

							p {
								color: #FF8400;
								font-size: 1.22rem;
								width: 3rem;
								cursor: pointer;
							}

							.collect {
								color: #000;
								cursor: pointer;
							}

							.collect2 {
								color: #108CDD;
								cursor: pointer;
							}

						}
					}
				}
			}
		}
	}
}

.SetMenu_tab {
	li {
		width: 5.67rem;
		height: 2.5rem;
		border-radius: 0.42rem;
		background: #ffffff;
		box-shadow: 0.17rem 0.17rem 0.33rem #7a7a7a;
		text-align: center;
		line-height: 2.5rem;
		color: #108cdd;
		cursor: pointer;

		&:nth-child(1) {
			margin-right: 1.5rem;
		}
	}

	.SetMenu_Show {
		background: #108cdd;
		color: #ffffff;
	}
}

.weight_back {
	margin: 0 auto;
	background: url('~@/assets/media/分组 1.png') no-repeat;
	background-size: 100%;
	width: 6rem;
	height: 2rem;
	color: #fff;
	font-size: 1.5rem;
	text-indent: 3.2rem;
}

// 被选中
.active {
	color: #168ad9 !important;
}

@keyframes shop_Show {
	to {
		transform: translateX(0%);
	}
}

@keyframes shop_none {
	to {
		transform: translateX(100%);
	}
}
</style>